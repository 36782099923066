<template>
  <div class="EcologicalCloud">
    <img src="../../assets/product/EcologicalCloud/1.png" class="headerimg" />

    <div class="EcologicalCloudinfo">
      <div class="title">生态云系统</div>
      <div class="title2">功能齐全，随你所需</div>

      <div class="EcologicalCloudlist">
        <div class="EcologicalCloudtitle">
          <div class="EcologicalCloudtext">基<br />础<br />功<br />能</div>
        </div>
        <div class="EcologicalCloudcardlist">
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/2.png" />
            <div class="EcologicalCloudcardtext">全国商城</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/3.png" />
            <div class="EcologicalCloudcardtext">O2O商城</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/4.png" />
            <div class="EcologicalCloudcardtext">社群社区</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/5.png" />
            <div class="EcologicalCloudcardtext">直播录播</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/6.png" />
            <div class="EcologicalCloudcardtext">定制开发</div>
          </div>
        </div>
      </div>

      <div class="EcologicalCloudlist">
        <div class="EcologicalCloudtitle">
          <div class="EcologicalCloudtext">增<br />值<br />功<br />能</div>
        </div>
        <div class="EcologicalCloudcardlist">
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/7.png" />
            <div class="EcologicalCloudcardtext">分销系统</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/8.png" />
            <div class="EcologicalCloudcardtext">区块链系统</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/9.png" />
            <div class="EcologicalCloudcardtext">SMS商客系统</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/10.png" />
            <div class="EcologicalCloudcardtext">广告联盟</div>
          </div>
          <div class="EcologicalCloudcard">
            <img src="../../assets/product/EcologicalCloud/11.png" />
            <div class="EcologicalCloudcardtext">商家联盟</div>
          </div>
        </div>
      </div>

      <div class="where">
        <div class="title">为什么选择生态云系统</div>
        <div class="title2">专人维护和监控，业务参数可定制调整</div>

        <div class="wherelist">
          <div class="wherecard">
            <img src="../../assets/product/EcologicalCloud/12.png" />
            <div class="wheretitle">没有类型限制</div>
            <div class="whereinfo">
              行业类、媒体类、区域类、政府类、协会类、俱乐部类等都可以运行
            </div>
          </div>
          <div class="wherecard">
            <img src="../../assets/product/EcologicalCloud/13.png" />
            <div class="wheretitle">功能自由组合</div>
            <div class="whereinfo">
              全国商城、O2O商城、社群社区、直播录播可根据企业需求组合使用
            </div>
          </div>
          <div class="wherecard">
            <img src="../../assets/product/EcologicalCloud/14.png" />
            <div class="wheretitle">特色升级功能</div>
            <div class="whereinfo">
              IM客商系统、分销系统、会员系统等提高产品性能
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="business">
      <div class="title">生态云系统运营理念</div>
      <div class="title2">纵深精耕，定制专属解决方案</div>

      <div class="businesslist">
        <div class="businesslistinfo">
          <div class="businessline">
            <div class="square"></div>
            <span style="vertical-align: middle">商品销售收入</span>
          </div>
          <div class="businessline">
            <div class="square"></div>
            <span style="vertical-align: middle">招商入驻费用</span>
          </div>
          <div class="businessline">
            <div class="square"></div>
            <span style="vertical-align: middle">商品销售招商分账</span>
          </div>
        </div>
        <div class="businesslistcenterimg">
          <img src="../../assets/product/EcologicalCloud/15.png" />
        </div>
        <div class="businesslistimg">
          <img src="../../assets/product/EcologicalCloud/16.png" />
        </div>
      </div>
    </div>

    <div class="EcologicalCloudinfo">
      <div class="profit">
        <div class="title">生态云系统四大合作“盈利模式”</div>
        <div class="title2">广告变现+增值变现+电商分账+股权融资</div>

        <div class="profitlist profittop">
          <div class="profitcard">
            <div class="profitcardtitle">
              广告变现
              <div class="cardtitle">Advertising Money</div>
            </div>
            <div class="profitinfo">
              <div class="profitcardline">
                <div class="round"></div>
                为商户进行平台引流触达目标客户
              </div>
              <div class="profitcardline">
                <div class="round"></div>
                催生私域流量，实现广告变现
              </div>
            </div>
          </div>
          <div class="profitcard">
            <div class="profitcardtitle">
              增值变现
              <div class="cardtitle">Value-added liquid</div>
            </div>
            <div class="profitinfo">
              <div class="profitcardline">
                <div class="round"></div>
                细分功能版图
              </div>
              <div class="profitcardline">
                <div class="round"></div>
                基本内容免费
              </div>
            </div>
            <div class="profitinfo">
              <div class="profitcardline">
                <div class="round"></div>
                升级优质内容
              </div>
              <div class="profitcardline">
                <div class="round"></div>
                收费高级内容
              </div>
            </div>
          </div>
        </div>

        <div class="profitlist">
          <div class="profitcard">
            <div class="profitcardtitle">
              电商分账
              <div class="cardtitle">Electricity collecting</div>
            </div>
            <div class="profitinfo">
              <div class="profitcardline">
                <div class="round"></div>
                入驻费用
              </div>
              <div class="profitcardline">
                <div class="round"></div>
                保证金
              </div>
            </div>
            <div class="profitinfo">
              <div class="profitcardline">
                <div class="round"></div>
                佣金抽取
              </div>
              <div class="profitcardline">
                <div class="round"></div>
                广告展位费
              </div>
            </div>
          </div>
          <div class="profitcard">
            <div class="profitcardtitle">
              股权融资
              <div class="cardtitle">Equity financing</div>
            </div>
            <div class="profitinfo">
              <div class="profitcardline">
                <div class="round"></div>
                匹配投资机构
              </div>
              <div class="profitcardline">
                <div class="round"></div>
                预约项目洽谈
              </div>
            </div>
            <div class="profitinfo">
              <div class="profitcardline">
                <div class="round"></div>
                实现资本对接
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage">
        <div class="title">生态云系统九大优势</div>
        <div class="title2">智能化操作呈现，打造效果型G3云推广获客系统</div>

        <div class="advantagelist">
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/17.png" />
            <div class="advantagetitle">商机把控</div>
            <div class="advantageinfo">合作业内商家</div>
            <div class="advantageinfo">把控产品政策</div>
            <div class="advantageinfo">挖掘行业商机</div>
          </div>
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/18.png" />
            <div class="advantagetitle">产品订制</div>
            <div class="advantageinfo">定制研发定制</div>
            <div class="advantageinfo">把控产品政策</div>
            <div class="advantageinfo">项目成果数据分析</div>
          </div>
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/19.png" />
            <div class="advantagetitle">品牌推广</div>
            <div class="advantageinfo">产品活动策划</div>
            <div class="advantageinfo">把控产品政策</div>
            <div class="advantageinfo">企业形象包装</div>
          </div>
        </div>

        <div class="advantagelist advantagetop">
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/20.png" />
            <div class="advantagetitle">资本对接</div>
            <div class="advantageinfo">匹配意向投资机构</div>
            <div class="advantageinfo">规避企业融资风险</div>
            <div class="advantageinfo">保障企业资本对接</div>
          </div>
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/21.png" />
            <div class="advantagetitle">渠道支持</div>
            <div class="advantageinfo">1000+合作媒体</div>
            <div class="advantageinfo">区域广告投放</div>
            <div class="advantageinfo">筛选定制推广渠道</div>
          </div>
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/22.png" />
            <div class="advantagetitle">市场招商</div>
            <div class="advantageinfo">商户合作对接</div>
            <div class="advantageinfo">招商方案定制</div>
            <div class="advantageinfo">招商加盟平台</div>
          </div>
        </div>

        <div class="advantagelist advantagetop">
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/23.png" />
            <div class="advantagetitle">技术支持</div>
            <div class="advantageinfo">100+技术团队</div>
            <div class="advantageinfo">6年核心技术沉淀</div>
            <div class="advantageinfo">上海技术研发中心</div>
          </div>
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/24.png" />
            <div class="advantagetitle">培训支持</div>
            <div class="advantageinfo">市场培训</div>
            <div class="advantageinfo">产品培训</div>
            <div class="advantageinfo">团队培训</div>
          </div>
          <div class="advantagecard">
            <img src="../../assets/product/EcologicalCloud/25.png" />
            <div class="advantagetitle">客服支持</div>
            <div class="advantageinfo">售前/后全程服务</div>
            <div class="advantageinfo">24小时服务响应</div>
            <div class="advantageinfo">1-2工作日售后解决</div>
          </div>
        </div>
      </div>
    </div>

    <div class="scene">
      <div class="title">生态云系统适用场景</div>
      <div class="title2">深耕细分行业，提供更加垂直的行业解决方案</div>
      <div class="scenelist">
        <img src="../../assets/product/EcologicalCloud/26.png" />
      </div>
    </div>

    <div class="serviceProcess">
      <div class="title">服务流程</div>
      <div class="title2">流程清晰、性能、安全、稳定的云产品</div>

      <div class="serviceProcessimg">
        <img src="../../assets/product/EcologicalCloud/28.png" />
      </div>
    </div>

    <div class="cloud">
      <div class="title">TA们都选择了生态云系统</div>
      <div class="title2">
        对多家企业的共同选择，生态云为您提供无忧的上云体验机会
      </div>

      <div class="cloudimg">
        <img src="../../assets/product/EcologicalCloud/27.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.cloud {
  margin-top: 126px;
  padding: 90px;
  background-color: #f5f7fa;
  .cloudimg {
    text-align: center;
    margin-top: 74px;
    img {
      width: 74%;
    }
  }
}

.serviceProcess {
  margin-top: 140px;
  .serviceProcessimg {
    margin-top: 46px;
    text-align: center;
    img {
      width: 74%;
    }
  }
}

.scene {
  margin-top: 140px;
  .scenelist {
    background-color: #000000;
    padding: 90px 260px;
    margin-top: 60px;
    text-align: center;
    img {
      width: 100%;
    }
  }
}

.advantage {
  margin-top: 140px;
  .advantagetop {
    margin-top: 44px !important;
  }
  .advantagelist {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .advantagecard {
      width: 437px;
      height: 428px;
      text-align: center;
      box-sizing: border-box;
      padding-top: 98px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      img {
        width: 70px;
      }
      .advantagetitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      .advantageinfo {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

.profit {
  .profittop {
    margin-top: 87px !important;
    margin-bottom: 40px !important;
  }
  .profitlist {
    display: flex;
    justify-content: space-between;
    margin: auto;
    .profitcard {
      width: 674px;
      height: 254px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 95px 70px;
      padding-right: 54px;
      .profitcardtitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        min-width: 228px;
        .cardtitle {
          font-size: 14px;
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
          margin-top: 20px;
        }
      }
      .profitinfo {
        .profitcardline {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 20px;
          .round {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #2f7ffc;
            margin-right: 25px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.business {
  background-color: #f5f7fa;
  padding: 90px 0px;
  .businesslist {
    margin: auto;
    margin-top: 40px;
    display: flex;
    box-sizing: border-box;
    padding: 0px 44px;
    justify-content: center;
    align-items: center;
    .businesslistinfo {
      .businessline {
        margin-top: 36px;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        .square {
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 3px solid #2f7ffc;
          margin-right: 39px;
          vertical-align: middle;
        }
      }
    }
    .businesslistcenterimg {
      margin-left: 154px;
      img {
        height: 106px;
      }
    }
    .businesslistimg {
      margin-left: 204px;
      img {
        height: 180px;
      }
    }
  }
}

.EcologicalCloudinfo {
  width: 73%;
  margin: 140px auto;
  .where {
    margin-top: 140px;
    .wherelist {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      .wherecard {
        width: 290px;
        text-align: center;
        img {
          width: 100px;
        }
        .wheretitle {
          margin-top: 40px;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }
        .whereinfo {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
        }
      }
    }
  }
  .EcologicalCloudlist {
    margin-top: 60px;
    display: flex;
    box-sizing: border-box;
    height: 250px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
    .EcologicalCloudtitle {
      .EcologicalCloudtext {
        font-size: 36px;
        font-weight: 500;
        color: #ffffff;
        line-height: 50px;
        margin-top: 17px;
        margin-bottom: 17px;
      }
      width: 123px;
      text-align: center;
      box-sizing: border-box;
      background-color: #2f7ffc;
    }

    .EcologicalCloudcardlist {
      padding: 60px 90px;
      justify-content: space-between;
      display: flex;
      width: 100%;
      .EcologicalCloudcard {
        text-align: center;
        img {
          width: 70px;
        }
        .EcologicalCloudcardtext {
          margin-top: 40px;
          font-size: 24px;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
        }
      }
    }
  }
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}

.title2 {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #616b80;
  line-height: 33px;
  margin-top: 20px;
}
</style>
